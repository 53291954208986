.wardrobe{
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 999;
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
    min-width: 270px;
    height: 100vh;
    overflow-y: scroll;

    .adjustable-height {
        height: 610px;
        margin-bottom: 20px;
        margin-top: 20px;
        overflow-y: scroll;    
        padding-right: 20px;

        h1 {
            margin-bottom: 30px;
            color: gray;
            font-weight: 400;
            font-size: 24px;
        }

        .link {
            display: flex;
            align-items: center;
            gap: 20px;

            .item{
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: 30px;
                text-decoration: none;

                img {
                    width: 80px;
                    height: 100px;
                    object-fit: cover;
                }

                .details {
                    h1 {
                        font-size: 18px;
                        font-weight: 500;   
                        word-wrap: break-word;
                        width: 300px;
                    }
                    p {
                        width: 300px;
                        color: gray;
                        margin-bottom: 10px;
                        font-size: 14px;
                    }

                    .price {
                        color: teal;
                    }

                }
            }

            .delete {
                cursor: pointer;
            }
        }
    
    }

    .total {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }

    button {
        width: 250px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        cursor: pointer;
        border: none;
        background-color: teal;
        color: white;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .reset {
        color: red;
        font-size: 12px;
        cursor: pointer;
        
    }

}

.overlay {
    opacity: 0.5;
    width: 100%;
    display: block;
    background: black;
    position: absolute;
    top: 0px;
    height: 100vh;
    left: 0;
    z-index: 99;
}