.wardrobepage {
    position: relative;
    top: 60px;
    // z-index: 999;
    background-color: white;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.5);
    min-width: 270px;
    margin: 0 auto;
    max-width: 950px;

    .column {
        grid-template-columns: repeat(3, 1fr);
        grid-auto-flow: row dense;
        width: 920px;
        display: grid;
        gap: 20px;

        h1 {
            margin-bottom: 30px;
            color: gray;
            font-weight: 400;
            font-size: 24px;
        }

        .link {
            display: block;
            align-items: center;
            gap: 20px;

            .item {
                display: block;
                align-items: center;
                gap: 20px;
                margin-bottom: 0px;
                width: 290px;
                text-decoration: none;

                img {
                    width: 100%;
                    height: 240px;
                    object-fit: cover;
                }

                .details {
                    h1 {
                        font-size: 18px;
                        font-weight: 500;
                    }

                    p {
                        width: 300px;
                        color: gray;
                        margin-bottom: 10px;
                        font-size: 14px;
                    }

                    .d-flex-space {
                        display: flex;
                        justify-content: space-between;
                    }

                    .price {
                        color: teal;
                    }

                }

                
            }
            .delete {
                margin-top: 10px;
                margin-bottom: 20px;
                cursor: pointer;
            }
        }
    }

}

.wardrobe-footer {
    display: block;

    .total {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
    }

    button {
        width: 250px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        cursor: pointer;
        border: none;
        background-color: teal;
        color: white;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .reset {
        color: red;
        font-size: 12px;
        cursor: pointer;

    }
}