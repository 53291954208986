.app {
    .loading {
        margin: 20% auto;
    }

    overflow-x: hidden;

    .link {
        color: inherit;
        text-decoration: none;
    }
}

.layout-wrapper {
    margin-top: 60px;
}

@media (max-width: 960px) {
    .layout-wrapper {
        margin-top: 120px;
    }

    .loading {
        display: flex;
        justify-content: center;
    }
}

.Toastify__toast-container {
    z-index: 9999999999 !important;
}