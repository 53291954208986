/* Add padding to containers */
* {
    box-sizing: border-box;
}

.reset {
    width: 337px;
    margin: 100px auto;
    h1 {
        text-align: center;
    }
    .container {
        padding: 16px;
        background-color: white;
        width: 100%;
        margin: 0 auto;
    }

    input[type=email], input[type=email] {
        width: 100%;
        padding: 15px;
        margin: 5px 0 22px 0;
        display: inline-block;
        border: 1px solid #979797;
        border-radius: 5px;
        background: #fff;
    }

    /* Full-width input fields */
    input[type=text], input[type=code] {
        width: 100%;
        padding: 15px;
        margin: 5px 0 22px 0;
        display: inline-block;
        border: 1px solid #979797;
        border-radius: 5px;
        background: #fff;
    }

    input[type=text], input[type=password] {
        width: 100%;
        padding: 15px;
        margin: 5px 0 22px 0;
        display: inline-block;
        border: 1px solid #979797;
        border-radius: 5px;
        background: #fff;
    }

    input[type=text]:focus, input[type=code]:focus, input[type=password]:focus {
        background-color: #fff;
        outline: none;
    }

    /* Overwrite default styles of hr */
    hr {
        border: 1px solid #f1f1f1;
        margin-bottom: 25px;
    }

    /* Set a style for the submit button */
    .submitbtn {
        background-color: teal;
        color: white;
        padding: 16px 20px;
        margin: 8px 0;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
        opacity: 0.9;

        &:hover {
            opacity: 1;
        }
        
    }

    /* Add a blue text color to links */
    a {
        color: #000;
    }

    /* Set a grey background color and center the text of the "sign in" section */
    .signin {
        background-color: #f1f1f1;
        text-align: center;
        border-radius: 5px;
    }
}