.contact {
    background-color: teal;
    color: white;
    padding: 15px;
    display: flex;
    justify-content: center;

    .wrapper {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        input {
            padding: 10px;
            border: none;
            border-radius: 5px 0 0 5px;
            width: 400px;
        }

        button {
            padding: 10px;
            color: white;
            background: #333;
            border-radius: 0 5px 5px 0;
            border: none;
        }

        .icons {
            display: flex;
            gap: 10px;

            a {
                color: #fff;
            }
        }
    }
}

@media (max-width: 1366px) {
    .contact {
        .wrapper {
            input {
                width: 200px;
            }
        }
    }
}

@media (max-width: 1124px) {
    .contact {
        .wrapper {
            input {
                width: auto;
            }
        }
    }
}


@media (max-width: 960px) {
    .contact {
        .wrapper {
            width: 100%;
            display: block;
            text-align: center;

            input {
                width: auto;
            }

            span {
                margin-bottom: 20px;
                display: block;
            }

            .icons {
                justify-content: center;
                margin-top: 20px;
            }
        }
    }
}