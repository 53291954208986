.products {
    padding: 30px 50px;
    display: flex;

    .loading {
        margin: 20% auto;
    }

    .search-filter-toggle{
        border-radius: 60px;
        font-size: 38px;
        background-color: #fff;
        align-items: center;
        display: flex;
        position: fixed;
        top: 15px;
        left: 15px;
        z-index: 9999999;
        color: #858585;
        border: none;
        cursor: pointer;
    }

    .left {
        flex: 1;
        position: sticky;
        height: 100%;
        top: 50px;
        padding-right: 30px;

        .search-header {
            display: flex;
            justify-content: space-between;
        }

        .filterItem {
            margin-bottom: 30px;

            .close {
                display: none;
            }

            h2 {
                font-weight: 400;
                margin-bottom: 20px;
            }

            .flex {
                display: flex;
                :nth-child(1) {
                    padding-right: 10px;
                }
                :last-child {
                    padding-left: 10px;
                }
            }

            .inputItem {
                margin-bottom: 10px;

                /* The slider itself */
                .slider {
                    -webkit-appearance: none;  /* Override default CSS styles */
                    appearance: none;
                    width: 100%; /* Full-width */
                    height: 25px; /* Specified height */
                    background: #d3d3d3; /* Grey background */
                    outline: none; /* Remove outline */
                    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
                    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
                    transition: opacity .2s;
                }
                
                /* Mouse-over effects */
                .slider:hover {
                    opacity: 1; /* Fully shown on mouse-over */
                }
                
                /* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
                .slider::-webkit-slider-thumb {
                    -webkit-appearance: none; /* Override default look */
                    appearance: none;
                    width: 25px; /* Set a specific slider handle width */
                    height: 25px; /* Slider handle height */
                    background: #000000; /* Green background */
                    cursor: pointer; /* Cursor on hover */
                }
                
                .slider::-moz-range-thumb {
                    width: 25px; /* Set a specific slider handle width */
                    height: 25px; /* Slider handle height */
                    background: #000000; /* Green background */
                    cursor: pointer; /* Cursor on hover */
                }
                
                label {
                    margin-left: 10px;
                }
            }
        }
    }

    .overlay {
        display: none;
    }

    .right {
        .list {
            display: grid;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 40px;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            width: 100%;
        
            .link {
                color: inherit;
                text-decoration: none;
                width: 100%;

                .card {
                    width: 100%;
                }
            }
        }

        flex: 3
    }

    .catImg {
        width: 100%;
        height: 300px;
        object-fit: cover;
        margin-bottom: 50px;
    }
}

@media (max-width: 1024px) {
    .app {
        .right {
            .list {
                display: grid;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 40px;
                grid-template-columns: 1fr 1fr;
                width: 100%;
            
                .link {
                    color: inherit;
                    text-decoration: none;
                    width: 100%;

                    .card {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 740px) {
    .products {
        display: block;

        .loading {
            display: flex;
            justify-content: center;
        }

        .search {
            width: 100%;
        }

        .catImg {
            display: none;
        }

        .left {
            padding: 20px;
            position: fixed;
            height: 100%;
            top: 0px;
            left: 0px;
            z-index: 99999999;
            background: #fff;
            width: 80%;
            display: block;
            overflow-y: scroll;

            .search-header {
                display: flex;
                justify-content: space-between;
                
                .close {
                    display: block;
                }
            }

            
        }

        .overlay {
            opacity: 0.5;
            width: 100%;
            display: block;
            background: black;
            position: absolute;
            top: 0px;
            height: 600vh;
            left: 0;
            z-index: 9999999;
        }

        .right {
            .list {
                display: block;
            }
            .card {
                width: 100%;
            }
        } 
    }
}