.list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 76px;
}

.load-more-btn {
    width: 100%;
    padding: 10px;
    background-color: white;
    color: teal;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    border: teal 1px solid;
    font-weight: 700;
}